import { RoutePath } from 'index';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { archiveOKR, selectOKR } from 'redux/slices/okrs';
import { AppDispatch } from 'redux/store';
import { OKR } from 'types';
import Badge from 'components/Badge';
import FloatingMenu from 'components/navigation/FloatingMenu';
import MenuItem from 'components/navigation/MenuItem';
import ActionModal from './ActionModal';

const OKRItem = (props: { okr: OKR; index: number }) => {
	const { okr, index: i } = props;
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const closeDeleteModal = useCallback(() => {
		setDeleteModalOpen(false);
	}, []);

	const handleDeleteOKR = async (): Promise<void> => {
		await dispatch(
			archiveOKR({
				okrId: okr?.id as string,
			})
		);
	};

	const navigateToOKRDetails = useCallback(
		(okr: OKR) => {
			if (
				okr === undefined ||
				okr === null ||
				okr.id === undefined ||
				okr.id === null
			)
				return;

			void dispatch(
				selectOKR({
					okrId: okr.id,
				})
			).then((args) => {
				navigate(`${RoutePath.OKR_DETAILS.replace(':okr', args.payload.id)}`);
			});
		},
		[navigate, dispatch]
	);

	return (
		<>
			<ActionModal
				isOpen={deleteModalOpen}
				onCancel={closeDeleteModal}
				onConfirm={handleDeleteOKR}
				buttonText="Delete"
				title={`Delete ${okr.objective}?`}
				description={
					<>
						<p>Are you sure you want to archive this objective?</p>
						<p>This action cannot be undone.</p>
					</>
				}
			/>
			<div
				key={okr.id}
				className="mt-4 flex flex-col items-center border border-[#35373C] rounded-lg lg:w-3/4 m-auto cursor-pointer"
				onClick={() => navigateToOKRDetails(okr)}
			>
				<div className="flex items-center justify-between w-full p-2 mx-auto ">
					<div className="flex items-center space-x-2">
						<Badge text={`O${i + 1}`} width="10" color="text-white" />
						{okr.owner_profile_image !== undefined &&
							okr.owner_profile_image !== null &&
							okr.owner_profile_image !== '' && (
								<img
									className="object-cover w-6 h-6 rounded-full"
									src={okr.owner_profile_image}
									alt="Owner"
								/>
							)}
					</div>
					<div className="flex-1 px-4 text-center text-white text-xs font-normal font-['Inter'] truncate">
						{okr.objective}
					</div>
					<div className="flex flex-row gap-0 items-center">
						<Badge
							backgroundColor="#D1FAE5"
							color="text-[#065F46]"
							text={`0/${okr.krs?.length ?? 0}`}
						/>
						<FloatingMenu>
							<MenuItem
								title="delete"
								onClick={(e: React.MouseEvent) => {
									e.stopPropagation();
									setDeleteModalOpen(true);
								}}
							/>
						</FloatingMenu>
					</div>
				</div>
			</div>
		</>
	);
};

export default OKRItem;
