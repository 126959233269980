import HomeSVG from 'icons/HomeSVG';
import { RoutePath } from 'index';
import { useNavigate } from 'react-router-dom';
import { OKR } from 'types';

interface BreadcrumbV2Props {
	title?: string;
	okr?: OKR;
}

const BreadcrumbV2 = (props: BreadcrumbV2Props): React.ReactElement => {
	const { title, okr } = props;
	const navigate = useNavigate();

	const navigateToOKRDetails = () => {
		if (
			okr === undefined ||
			okr === null ||
			okr.id === undefined ||
			okr.id === null
		)
			return;

		navigate(RoutePath.OKR_DETAILS.replace(':okr', okr?.id));
	};

	return (
		<div className="Placeholder flex flex-row gap-1">
			<div className="text-gray-500 w-full text-xs font-normal font-['Inter'] leading-normal cursor-pointer truncate flex flex-row gap-1">
				<div
					className="cursor-pointer"
					onClick={(e) => {
						e.preventDefault();
						navigate(RoutePath.DASHBOARD);
					}}
				>
					<HomeSVG />
				</div>
				<div onClick={navigateToOKRDetails}>
					{okr !== null && okr !== undefined ? `> ${okr?.objective}` : ''}
				</div>
				{title !== null && title !== undefined && (
					<>
						{okr !== null && okr !== undefined ? <span>&gt;</span> : null}
						<div className="text-white text-xs font-normal font-['Inter'] leading-normal truncate">
							{title}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default BreadcrumbV2;
