const MenuSVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="white"
			className="w-4 h-4 ml-2"
		>
			<path
				fillRule="evenodd"
				d="M10.5 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm0 6a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default MenuSVG;
