import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'data/fetcher';
import { RootState } from 'redux/store';
import { API_ROUTES } from 'routes';
import { Follower, FollowerStatus, KR, OKR } from 'types';

// slice state type
interface OKRState {
	selectOKRSStatus?: 'idle' | 'loading' | 'failed';
	selectOKRStatus?: 'idle' | 'loading' | 'failed';
	selectKRStatus?: 'idle' | 'loading' | 'failed';
	okrs: OKR[];
	selectedOKR?: OKR;
	selectedKR?: KR;
	error?: string;
}

// slice initial state
const initialState: OKRState = {
	selectOKRSStatus: 'loading',
	selectOKRStatus: 'loading',
	selectKRStatus: 'loading',
	okrs: [],
};

const addOrUpdateOKR = createAsyncThunk(
	'okrs/addOrUpdateOKR',
	async (okr: OKR, thunkAPI) => {
		const response = await axiosInstance.post(API_ROUTES.CORE_OKR_UPDATE, okr, {
			signal: thunkAPI.signal,
		});
		return response.data.okr;
	}
);

const addOrUpdateKR = createAsyncThunk(
	'okrs/addOrUpdateKR',
	async (params: { kr: KR; okrId: string }, thunkAPI) => {
		const response = await axiosInstance.post(
			API_ROUTES.CORE_KR_UPDATE.replace(':okr', params.okrId),
			params.kr,
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data.okr;
	}
);

const selectOKRs = createAsyncThunk('okrs/selectOKRs', async (_, thunkAPI) => {
	const response = await axiosInstance.get(API_ROUTES.CORE_ACTIVE_OKRS, {
		signal: thunkAPI.signal,
	});
	return response.data.okrs;
});

const selectOKR = createAsyncThunk(
	'okrs/selectOKR',
	async (params: { okrId: string }, thunkAPI) => {
		const response = await axiosInstance.get(
			API_ROUTES.CORE_OKR.replace(':okr', params.okrId),
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data.okr;
	}
);

const selectKR = createAsyncThunk(
	'okrs/selectKR',
	async (params: { okrId: string; krId: string }, thunkAPI) => {
		const response = await axiosInstance.get(
			API_ROUTES.CORE_KR.replace(':okr', params.okrId).replace(
				':kr',
				params.krId
			),
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data.kr;
	}
);

const refetchSelectedKR = createAsyncThunk<KR, undefined, { state: RootState }>(
	'okrs/refetchSelectedKR',
	async (_, thunkAPI) => {
		const okrId = thunkAPI.getState().okrs.selectedOKR?.id;
		const krId = thunkAPI.getState().okrs.selectedKR?.id;

		if (
			okrId === undefined ||
			okrId === null ||
			okrId === '' ||
			krId === undefined ||
			krId === null ||
			krId === ''
		)
			return thunkAPI.rejectWithValue('No KR selected');

		const response = await axiosInstance.get(
			API_ROUTES.CORE_KR.replace(':okr', okrId).replace(':kr', krId),
			{
				signal: thunkAPI.signal,
			}
		);

		return response.data.kr;
	}
);

const toggleFollow = createAsyncThunk(
	'okrs/toggleKRFollow',
	async (params: { krId: string }, thunkAPI) => {
		const response = await axiosInstance.post(
			API_ROUTES.CORE_KR_FOLLOW,
			{
				krId: params.krId,
			},
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data.followers;
	}
);

const archiveKR = createAsyncThunk(
	'okrs/archiveKR',
	async (params: { krId: string; okrId: string }, thunkAPI) => {
		const response = await axiosInstance.delete(
			API_ROUTES.CORE_KR.replace(':kr', params.krId).replace(
				':okr',
				params.okrId
			),
			{
				signal: thunkAPI.signal,
			}
		);

		if (response.status !== 200) {
			return thunkAPI.rejectWithValue('Failed to archive KR');
		}

		return response.data;
	}
);

const archiveOKR = createAsyncThunk(
	'okrs/archiveOKR',
	async (params: { okrId: string }, thunkAPI) => {
		const response = await axiosInstance.delete(
			API_ROUTES.CORE_OKR.replace(':okr', params.okrId),
			{
				signal: thunkAPI.signal,
			}
		);

		if (response.status !== 200) {
			return thunkAPI.rejectWithValue('Failed to archive OKR');
		}

		return response.data;
	}
);

const refetchOKR = createAsyncThunk<OKR, undefined, { state: RootState }>(
	'okrs/refetchOKR',
	async (_, thunkAPI) => {
		const okr = thunkAPI.getState().okrs.selectedOKR?.id;

		if (okr === undefined || okr === null || okr === '')
			return thunkAPI.rejectWithValue('No OKR selected');

		const response = await axiosInstance.get(
			API_ROUTES.CORE_OKR.replace(':okr', okr),
			{
				signal: thunkAPI.signal,
			}
		);

		return response.data.okr;
	}
);

// slice configuration
const okrsSlice = createSlice({
	name: 'okrs',
	initialState,
	reducers: {
		cleanOKR: (state) => {
			state.selectOKRStatus = 'loading';
			state.selectKRStatus = 'loading';
			state.selectedOKR = undefined;
			state.selectedKR = undefined;
		},
		cleanKR: (state) => {
			state.selectKRStatus = 'loading';
			state.selectedKR = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			// selectOKR cases
			.addCase(selectOKRs.pending, (state) => {
				state.selectOKRSStatus = 'loading';
				state.error = undefined;
			})
			.addCase(selectOKRs.fulfilled, (state, action) => {
				state.selectOKRSStatus = 'idle';
				state.okrs = action.payload;
			})
			.addCase(selectOKRs.rejected, (state, action) => {
				state.selectOKRSStatus = 'failed';
				state.error = action.error.message;
			})
			.addCase(refetchOKR.fulfilled, (state, action) => {
				state.selectOKRStatus = 'idle';
				state.selectedOKR = action.payload;
			})
			// fetch OKR cases
			.addCase(selectOKR.pending, (state) => {
				state.selectOKRStatus = 'loading';
				state.error = undefined;
			})
			.addCase(selectOKR.fulfilled, (state, action) => {
				state.selectOKRStatus = 'idle';
				state.selectedOKR = action.payload;
			})
			.addCase(selectOKR.rejected, (state, action) => {
				state.selectOKRStatus = 'failed';
				state.error = action.error.message;
			})
			// fetch KR cases
			.addCase(selectKR.pending, (state) => {
				state.selectKRStatus = 'loading';
				state.error = undefined;
			})
			.addCase(selectKR.fulfilled, (state, action) => {
				state.selectKRStatus = 'idle';
				state.selectedKR = action.payload;
			})
			.addCase(selectKR.rejected, (state, action) => {
				state.selectKRStatus = 'failed';
				state.error = action.error.message;
			})
			.addCase(refetchSelectedKR.fulfilled, (state, action) => {
				state.selectKRStatus = 'idle';
				state.selectedKR = action.payload;
			})
			.addCase(archiveKR.fulfilled, (state, action) => {
				const krId = action.meta.arg.krId;

				if (state.selectedOKR !== undefined && state.selectedOKR !== null) {
					state.selectedOKR.krs = state.selectedOKR.krs?.filter(
						(kr) => kr.id !== krId
					);
				}
			})
			.addCase(archiveOKR.fulfilled, (state, action) => {
				const okrId = action.meta.arg.okrId;

				state.okrs = state.okrs.filter((okr) => okr.id !== okrId);
			})
			.addCase(toggleFollow.fulfilled, (state, action) => {
				const followers: Follower[] = action.payload;
				const krId = action.meta.arg.krId;

				if (state.selectedOKR !== undefined && state.selectedOKR !== null) {
					state.selectedOKR.krs = state.selectedOKR.krs?.map((kr) => {
						if (kr.id === krId) {
							kr.followers = followers;
						}
						return kr;
					});
				}

				state.okrs = state.okrs.map((okr) => {
					okr.krs = okr.krs?.map((kr) => {
						if (kr.id === krId) {
							kr.followers = followers;
						}
						return kr;
					});
					return okr;
				});
			})
			.addCase(addOrUpdateOKR.fulfilled, (state, action) => {
				state.selectOKRStatus = 'idle';
				state.selectedOKR = action.payload;
			});
	},
});

// exporting actions
export {
	addOrUpdateOKR,
	addOrUpdateKR,
	selectOKRs,
	selectOKR,
	selectKR,
	refetchOKR,
	refetchSelectedKR,
	toggleFollow,
	archiveKR,
	archiveOKR,
};
export const { cleanOKR, cleanKR } = okrsSlice.actions;

// exporting selectors
export const useOKRsState = () => (state: RootState) => state.okrs;
export const isFollowingKR = (kr: KR) => (state: RootState) => {
	const followers = kr.followers;
	const userId = state.auth.id;

	if (
		followers === undefined ||
		followers === null ||
		userId === undefined ||
		userId === null
	)
		return false;

	return followers.some(
		(follower) =>
			follower.id === userId && follower.status === FollowerStatus.ACTIVE
	);
};

export const useKRState = () => (state: RootState) => {
	const { selectedKR, selectKRStatus, error } = state.okrs;
	return { selectedKR, selectKRStatus, error };
};

export default okrsSlice.reducer;
