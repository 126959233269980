import React, { ReactElement, useEffect } from 'react';
import CloseSVG from 'icons/CloseSVG';
import BlueButton from 'components/BlueButton';

const ActionModal = (props: {
	isOpen: boolean;
	onCancel: () => void;
	onConfirm: () => Promise<void>;
	title: string;
	description: ReactElement | string;
	buttonText: string;
}) => {
	const { isOpen, onCancel, onConfirm, title, description, buttonText } = props;
	const [isOnConfimExecuting, setIsOnConfimExecuting] = React.useState(false);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onCancel();
			}
		};

		if (isOpen) {
			document.addEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, onCancel]);

	const handleConfirm = async () => {
		setIsOnConfimExecuting(true);
		await onConfirm()
			.finally(() => {
				onCancel();
			})
			.finally(() => {
				setIsOnConfimExecuting(false);
			});
	};

	return (
		<>
			{isOpen && (
				<div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-[#35373C] rounded-lg shadow-lg max-w-md w-full relative p-6 text-slate-200">
						<button
							onClick={onCancel}
							className="absolute w-4 top-3 right-3 text-white transition"
						>
							<CloseSVG />
						</button>
						<h2 className="text-2xl font-semibold mb-4">{title}</h2>
						<p className="mb-1">{description}</p>
						<div className="flex justify-end space-x-3">
							<BlueButton
								disabled={isOnConfimExecuting}
								onClick={handleConfirm}
								className="btn btn-primary"
							>
								{buttonText}
							</BlueButton>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ActionModal;
