import React, { useCallback, useEffect } from 'react';
import { ITeamMember, OKR } from 'types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { addOrUpdateOKR } from 'redux/slices/okrs';
import ObjectiveInput from 'components/Forms/ObjectiveInput';
import BlueButton from 'components/BlueButton';
import OwnerTag from 'components/Forms/OwnerTag';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'index';
import Container from 'pages/authenticated/Container';
import { Welcome } from 'components/company/Welcome';
import * as amplitude from '@amplitude/analytics-browser';
import BreadcrumbV2 from 'components/BreadcrumbV2';

const objectivePlaceholder = 'Type your objective...';

interface AddGoalProps {
	header?: boolean;
	breadcrumb?: boolean;
}

const AddGoal = (props: AddGoalProps): React.ReactElement => {
	const { header } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const objectiveRef = React.createRef<HTMLTextAreaElement>();
	const [owner, setOwner] = React.useState<ITeamMember>();
	const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

	const onSelectHandler = (selected: ITeamMember | undefined) => {
		setOwner(selected);
	};

	const validateForm = useCallback(() => {
		const isObjectiveFilled = objectiveRef.current?.value.trim() !== '';
		const isOwnerSelected = owner !== undefined;

		return isObjectiveFilled && isOwnerSelected;
	}, [owner, objectiveRef]);

	useEffect(() => {
		const handleInputChange = () => {
			setIsButtonDisabled(!validateForm());
		};

		const titleElement = objectiveRef.current;
		titleElement?.addEventListener('input', handleInputChange);
		handleInputChange();

		return () => {
			titleElement?.removeEventListener('input', handleInputChange);
		};
	}, [owner, validateForm, objectiveRef]);

	const handleSubmit = async () => {
		if (
			owner === undefined ||
			owner === null ||
			objectiveRef.current?.value === undefined ||
			objectiveRef.current?.value === null ||
			objectiveRef.current?.value === '' ||
			objectiveRef.current?.value === objectivePlaceholder
		)
			return;

		const okr: OKR = {
			owner: owner.id,
			objective: objectiveRef.current?.value,
			status: 'active',
		};

		await dispatch(addOrUpdateOKR(okr)).then((createdOkr) => {
			void amplitude.track('New Objective', {
				title: okr.objective,
				owner: okr.owner,
			});

			if (
				createdOkr.payload?.id === undefined ||
				createdOkr.payload?.id === null
			)
				return;

			navigate(RoutePath.OKR_DETAILS.replace(':okr', createdOkr.payload?.id));
		});
	};

	const form = (
		<div className="w-full pb-6 ">
			<div className="m-4 flex flex-col gap-4">
				<BreadcrumbV2 title="New Objective" />
				<ObjectiveInput
					contentRef={objectiveRef}
					placeholder={objectivePlaceholder}
				/>
				<OwnerTag onSelect={onSelectHandler} />
				<div className="Divider w-ful h-px bg-neutral-700 border-neutral-700" />
				<div className="flex content-end justify-end">
					<BlueButton
						type="button"
						onClick={handleSubmit}
						disabled={isButtonDisabled}
					>
						Add Objective
					</BlueButton>
				</div>
			</div>
		</div>
	);

	if (header === true) {
		return (
			<Container>
				<Welcome />
				<div className="mt-4 flex flex-col items-center border border-[#35373C]  rounded-lg lg:w-3/4 m-auto">
					{form}
				</div>
			</Container>
		);
	}

	return form;
};

export default AddGoal;
