import React, { useCallback, useEffect } from 'react';
import Container from 'pages/authenticated/Container';
import useSelectedOKR from 'hooks/useSelectedOKR';
import ProgressBadge from 'components/okr/ProgressBadge';
import OKRIndexBadge from 'components/okr/OKRIndexBadge';
import TutorialMessage from 'components/callouts/TutorialMessage';
import BlueButton from 'components/BlueButton';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'index';
import { Loader } from 'components/loader/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { cleanKR } from 'redux/slices/okrs';
import { resetCheckpointState } from 'redux/slices/checkpoints';
import BreadcrumbV2 from 'components/BreadcrumbV2';
import KRItem from 'components/keyResults/KRItem';

interface OKRDetailsProps {
	header?: boolean;
}

const OKRDetails = (props: OKRDetailsProps): React.ReactElement => {
	const { header } = props;
	const { selectedOKR, selectOKRStatus, okrs } = useSelectedOKR();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const addNewMilestoneHandler = useCallback(() => {
		if (
			selectedOKR === undefined ||
			selectedOKR === null ||
			selectedOKR.id === undefined ||
			selectedOKR.id === null
		)
			return;

		navigate(RoutePath.ADD_MILESTONE.replace(':okr', selectedOKR.id));
	}, [navigate, selectedOKR]);

	useEffect(() => {
		void dispatch(cleanKR());
		void dispatch(resetCheckpointState());
	}, [dispatch]);

	if (
		selectOKRStatus === 'loading' ||
		selectedOKR === undefined ||
		selectedOKR === null
	) {
		return (
			<Container>
				<Loader />;
			</Container>
		);
	}

	if (selectOKRStatus === 'failed') {
		navigate(RoutePath.DASHBOARD);
	}

	const okrDetail = (
		<div className="flex flex-col w-full p-4 pb-6 gap-4">
			<div className="flex flex-col gap-4">
				<div className="Callout h-10 p-2 rounded-[64px] border border-[#35373c] justify-between items-center inline-flex">
					{selectedOKR !== undefined && selectedOKR !== null && (
						<OKRIndexBadge okr={selectedOKR} okrs={okrs} />
					)}
					<div
						title={selectedOKR?.objective}
						className="TodaySTotalSales text-center text-white text-xs font-normal font-inter truncate"
					>
						{selectedOKR?.objective}
					</div>
					{selectedOKR !== undefined && selectedOKR !== null && (
						<ProgressBadge okr={selectedOKR} />
					)}
				</div>
			</div>
			{selectedOKR?.krs?.length === 0 && (
				<TutorialMessage>
					<p>Here you can keep track your objective progress 🎉</p>
					<p>Setup your milestones to share an update to your team later</p>
				</TutorialMessage>
			)}
			{selectedOKR?.krs?.map((kr) => (
				<KRItem key={kr.id} kr={kr} />
			))}
			<div className="flex content-end justify-end ">
				<BlueButton type="button" onClick={addNewMilestoneHandler}>
					Set up a new milestone
				</BlueButton>
			</div>
		</div>
	);

	if (header === true) {
		return (
			<Container>
				<div className="mt-4 flex flex-col items-center border border-[#35373C] rounded-lg lg:w-3/4 m-auto">
					<div className="w-full p-2 pl-6">
						<BreadcrumbV2 okr={selectedOKR} />
					</div>
					{okrDetail}
				</div>
			</Container>
		);
	}

	return okrDetail;
};

export default OKRDetails;
