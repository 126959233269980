import React, { useState, useRef } from 'react';
import MenuItem from './MenuItem';
import MenuSVG from 'icons/MenuSVG';

const FloatingMenu = ({ children }: { children: React.ReactNode }) => {
	const [isOpen, setIsOpen] = useState(false); // Toggle for menu visibility
	const menuRef = useRef(null); // Reference to the floating menu

	const areChildrenValid = () => {
		return React.Children.toArray(children).every((child) => {
			return (
				React.isValidElement(child) &&
				(child as React.ReactElement).type === MenuItem
			);
		});
	};

	if (!areChildrenValid()) {
		throw new Error(
			'FloatingMenu only accepts MenuItem components as children.'
		);
	}

	const toggleMenu = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsOpen((prev) => !prev);
	};

	return (
		<div className="relative inline-block">
			<button onClick={toggleMenu} className="cursor-pointer flex">
				<MenuSVG />
			</button>

			{isOpen && (
				<div
					ref={menuRef}
					className="absolute left-[-6rem] mt-2 w-32 bg-white border-[#35373c] shadow-lg border rounded-lg rounded-tr-none"
				>
					<ul>
						{React.Children.map(children, (child, index) => (
							<li key={index} className=" text-default hover:font-medium">
								{child}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default FloatingMenu;
