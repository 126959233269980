/**
 * Generate rrule based on frequency and day of week
 * The hour and minute are hardcoded to 8:00 AM today
 * This can be changed to a user input or a user setting in the future
 */
export const generateRRule = ({
	frequency,
	day,
	hour = 8,
	minute = 0,
}: {
	frequency: 'weekly' | 'biweekly' | 'monthly';
	day: number;
	hour?: number;
	minute?: number;
}) => {
	if (day < 0 || day > 6) {
		throw new Error('Invalid day');
	}

	const frequencyMap: { [key: string]: string } = {
		weekly: 'WEEKLY',
		biweekly: 'WEEKLY;INTERVAL=2',
		monthly: 'MONTHLY',
	};

	const rrule = `FREQ=${frequencyMap[frequency]};BYDAY=${
		['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][day]
	};BYHOUR=${hour};BYMINUTE=${minute}`;

	return rrule;
};
