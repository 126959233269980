import React from 'react';

const MenuItem = ({
	title,
	onClick,
}: {
	title: string;
	onClick: (e: React.MouseEvent) => void;
}) => {
	return (
		<div
			onClick={onClick}
			className="cursor-pointer px-4 py-2 text-right text-sm"
		>
			{title}
		</div>
	);
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
