import React, { useEffect, useRef } from 'react';
import Container from 'pages/authenticated/Container';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'index';
import { Loader } from 'components/loader/Loader';
import useSelectedKR from 'hooks/useSelectedKR';
import useSelectedOKR from 'hooks/useSelectedOKR';
import OKRIndexBadge from 'components/okr/OKRIndexBadge';
import ProgressBadge from 'components/okr/ProgressBadge';
import AvatarGroup from 'components/avatars/AvatarGroup';
import ProgressBar from 'components/kr/ProgressBar';
import FollowUpForm from 'components/milestone/FollowupForm';
import BlueButton from 'components/BlueButton';
import {
	addCheckpoint,
	listCheckpoints,
	useCheckpointsState,
	resetCheckpointState,
} from 'redux/slices/checkpoints';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { CheckpointForm } from 'types';
import { refetchSelectedKR } from 'redux/slices/okrs';
import AvatarCircle from 'components/avatars/AvatarCircle';
import { formatDatetimeToHumanReadable } from 'utils';
import BreadcrumbV2 from 'components/BreadcrumbV2';

interface KRDetailsProps {
	header?: boolean;
}

const KRDetails = (props: KRDetailsProps): React.ReactElement => {
	const { header } = props;
	const { selectedOKR, selectOKRStatus, okrs } = useSelectedOKR();
	const { selectedKR, selectKRStatus } = useSelectedKR();
	const { checkpoints, checkpointListStatus } = useSelector(
		useCheckpointsState()
	);

	const titleRef = useRef<HTMLTextAreaElement>(null);
	const valueRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const navigateToOKRDetails = () => {
		if (
			selectedOKR === undefined ||
			selectedOKR === null ||
			selectedOKR.id === undefined ||
			selectedOKR.id === null
		)
			return;

		navigate(RoutePath.OKR_DETAILS.replace(':okr', selectedOKR.id));
	};

	useEffect(() => {
		void dispatch(resetCheckpointState());
	}, [dispatch]);

	const onSubmitKRFollowUp = async () => {
		if (
			selectedKR === undefined ||
			selectedKR === null ||
			selectedKR.id === undefined ||
			selectedKR.id === null
		)
			return;

		if (
			titleRef.current?.value === undefined ||
			titleRef.current?.value === null ||
			titleRef.current?.value.trim().length === 0
		)
			return;

		if (
			valueRef.current?.value === undefined ||
			valueRef.current?.value === null ||
			valueRef.current?.value.trim().length === 0
		)
			return;

		const data: CheckpointForm = {
			kr: selectedKR.id,
			current: parseFloat(valueRef.current?.value ?? '0'),
			target: parseFloat(selectedKR.target.toString()),
			checkpoint: titleRef.current?.value,
			is_truncated: false,
		};

		await dispatch(addCheckpoint(data))
			.then(() => {
				return dispatch(refetchSelectedKR());
			})
			.then(() => {
				return dispatch(
					listCheckpoints({
						kr: selectedKR?.id as string,
						cleanCheckpointList: true,
					})
				);
			})
			.then(() => {
				if (titleRef.current !== null) {
					titleRef.current.value = '';
				}
				if (valueRef.current !== null) {
					valueRef.current.value = '';
				}
			});
	};

	if (
		(selectKRStatus === 'loading' || selectOKRStatus === 'loading') &&
		(selectedKR === undefined ||
			selectedKR === null ||
			selectedOKR === undefined ||
			selectedOKR === null)
	) {
		return (
			<Container>
				<Loader />
			</Container>
		);
	}

	if (selectKRStatus === 'failed' || selectOKRStatus === 'failed') {
		navigate(RoutePath.DASHBOARD);
	}

	const okrDetail = (
		<div className="flex flex-col w-full p-4 pb-6 gap-4">
			<div className="flex flex-col gap-4">
				<div
					className="Callout h-10 p-2 rounded-[64px] border border-[#35373c] justify-between items-center inline-flex cursor-pointer"
					onClick={navigateToOKRDetails}
				>
					{selectedOKR !== undefined && selectedOKR !== null && (
						<OKRIndexBadge okr={selectedOKR} okrs={okrs} />
					)}
					<div
						title={selectedOKR?.objective}
						className="TodaySTotalSales text-center text-white text-xs font-normal font-inter truncate"
					>
						{selectedOKR?.objective}
					</div>
					{selectedOKR !== undefined && selectedOKR !== null && (
						<ProgressBadge okr={selectedOKR} />
					)}
				</div>
			</div>
			{selectedKR !== undefined && selectedKR !== null && (
				<>
					<div
						key={selectedKR.id}
						className="Overview  p-2 bg-[#1b1c1f] rounded-lg border border-[#35373c] flex-col justify-start items-start gap-2 inline-flex"
					>
						<div className="Frame626468 self-stretch justify-between items-start inline-flex">
							<div className="TodaySTotalSales px-1 text-white text-xs font-normal font-inter truncate">
								{selectedKR.objective}
							</div>
							<AvatarGroup members={selectedKR.followers ?? []} disabled />
						</div>
						<ProgressBar kr={selectedKR} />
					</div>
					<div className="Frame2 p-4 bg-[#2a2c32] rounded-2xl flex-col justify-center items-end gap-4 inline-flex">
						<FollowUpForm
							kr={selectedKR}
							placeholder="Type your milestone follow-up..."
							titleRef={titleRef}
							valueRef={valueRef}
						/>
						<BlueButton fullWidth type="button" onClick={onSubmitKRFollowUp}>
							Send Milestone Follow-up{' '}
						</BlueButton>
					</div>
					<div className="flex flex-col gap-2">
						{checkpointListStatus === 'loading' && <Loader />}
						{checkpointListStatus === 'idle' &&
							checkpoints.map((checkpoint) => (
								<div
									key={checkpoint.id}
									className="p-4 bg-[#2a2c32] rounded-2xl flex-col justify-center items-start gap-4 inline-flex"
								>
									<div className="self-stretch justify-start items-center gap-2.5 inline-flex">
										<AvatarCircle
											member={{
												profile_image: checkpoint.author_profile_image ?? '',
											}}
										/>
										<div className="flex flex-row text-right gap-2">
											<span className="text-gray-500 text-sm font-normal font-inter leading-tight">
												{formatDatetimeToHumanReadable(
													checkpoint.createdAt ?? ''
												)}
											</span>
											<span className="text-white text-sm font-medium font-inter leading-tight">
												{checkpoint.author_name}
											</span>
										</div>
									</div>
									<div className="self-stretch text-[#8d8f92] text-sm font-normal font-inter leading-tight break-words whitespace-pre-wrap">
										{checkpoint.checkpoint}
									</div>
									<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
										<div className="flex-col justify-start items-start gap-2 inline-flex">
											<div className="pl-2 pr-3 py-0.5 bg-[#f2f2f7] rounded-xl justify-center items-center gap-1.5 inline-flex">
												<div className="Text text-center text-[#222222] text-xs font-medium font-inter leading-tight">
													{`from ${checkpoint.previous ?? 0} to ${
														checkpoint.current ?? 0
													}`}
												</div>
											</div>
										</div>
										<div className="flex-col justify-start items-start gap-2 inline-flex">
											<div className="Badge pl-2 pr-3 py-0.5 bg-emerald-100 rounded-xl justify-center items-center gap-1.5 inline-flex">
												<div className="BadgeDot w-2 h-2 relative">
													<div className="Dot w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-emerald-800 rounded-full" />
												</div>
												<div className="Text text-center text-emerald-800 text-xs font-medium font-inter leading-tight">
													{checkpoint.progress_in_unit}
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
					</div>
				</>
			)}
		</div>
	);

	if (header === true) {
		return (
			<Container>
				<div className="mt-4 flex flex-col items-center border border-[#35373C] rounded-lg lg:w-3/4 m-auto">
					<div className="w-full p-2 pl-6">
						<BreadcrumbV2
							title={selectedKR?.objective ?? ''}
							okr={selectedOKR}
						/>
					</div>
					{okrDetail}
				</div>
			</Container>
		);
	}

	return okrDetail;
};

export default KRDetails;
