import AvatarGroup from 'components/avatars/AvatarGroup';
import useSelectedOKR from 'hooks/useSelectedOKR';
import { RoutePath } from 'index';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { KR } from 'types';
import ProgressBar from 'components/kr/ProgressBar';
import FloatingMenu from 'components/navigation/FloatingMenu';
import MenuItem from 'components/navigation/MenuItem';
import ActionModal from 'components/keyResults/ActionModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { archiveKR } from 'redux/slices/okrs';

const KRItem = (props: { kr: KR }) => {
	const { kr } = props;
	const navigate = useNavigate();
	const { selectedOKR } = useSelectedOKR();
	const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
	const dispatch = useDispatch<AppDispatch>();

	const closeDeleteModal = useCallback(() => {
		setDeleteModalOpen(false);
	}, []);

	const handleDeleteKR = async (): Promise<void> => {
		await dispatch(
			archiveKR({
				krId: kr.id as string,
				okrId: selectedOKR?.id as string,
			})
		);
	};

	const onKRClick = useCallback(
		(krId?: string | null) => {
			if (
				selectedOKR === undefined ||
				selectedOKR === null ||
				selectedOKR.id === undefined ||
				selectedOKR.id === null ||
				krId === undefined ||
				krId === null
			)
				return;

			navigate(
				RoutePath.KR_DETAILS.replace(':okr', selectedOKR.id).replace(
					':kr',
					krId
				)
			);
		},
		[navigate, selectedOKR]
	);

	if (kr === undefined || kr === null) {
		return null;
	}

	return (
		<>
			<ActionModal
				isOpen={deleteModalOpen}
				onCancel={closeDeleteModal}
				onConfirm={handleDeleteKR}
				buttonText="Delete"
				title={`Delete ${kr.objective}?`}
				description={
					<>
						<p>Are you sure you want to archive this key result?</p>
						<p>This action cannot be undone.</p>
					</>
				}
			/>
			<div
				key={kr.id}
				className="Overview  p-2 bg-[#1b1c1f] rounded-lg border border-[#35373c] flex-col justify-start items-start gap-2 inline-flex cursor-pointer"
				onClick={() => onKRClick(kr.id)}
			>
				<div className="Frame626468 self-stretch justify-between items-start inline-flex">
					<div className="TodaySTotalSales px-1 text-white text-xs font-normal font-inter truncate">
						{kr.objective}
					</div>
					<div className="flex flex-row gap-2">
						<AvatarGroup members={kr.followers ?? []} disabled />
						<FloatingMenu>
							<MenuItem
								title="delete"
								onClick={(e: React.MouseEvent) => {
									e.stopPropagation();
									setDeleteModalOpen(true);
								}}
							/>
						</FloatingMenu>
					</div>
				</div>
				<ProgressBar kr={kr} />
			</div>
		</>
	);
};

export default KRItem;
