import BlueButton from 'components/BlueButton';
import OKRItem from 'components/keyResults/OKRItem';
import { Loader } from 'components/loader/Loader';
import { RoutePath } from 'index';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useOKRsState } from 'redux/slices/okrs';

interface OKRListProps {
	editable?: boolean;
}

const OKRList = (props: OKRListProps): React.ReactElement => {
	const navigate = useNavigate();
	const { okrs, selectOKRSStatus } = useSelector(useOKRsState());

	const addNewObjectiveHandler = useCallback(() => {
		navigate(RoutePath.ADD_GOAL);
	}, [navigate]);

	if (selectOKRSStatus === 'loading') {
		return <Loader />;
	}

	return (
		<div className="w-full pb-6 ">
			{okrs.map((okr, i) => (
				<OKRItem key={okr.id} okr={okr} index={i} />
			))}
			<div className="Divider w-ful h-px bg-neutral-700 border-neutral-700 my-4" />

			<div className="flex content-end justify-end ">
				<BlueButton type="button" onClick={addNewObjectiveHandler}>
					Set up a new objective
				</BlueButton>
			</div>
		</div>
	);
};

export default OKRList;
